<template>
  <div class="view-card">

    <b-overlay :show="showOverlay">
      <b-card>
        <template #header>
          <p class="name">
            <span v-if="!issueId">User</span>
            <span v-else> {{ owner }} </span>
          </p>

          <small class="role text-muted" v-if="issueId">
            Owner
          </small>
        </template>

        <b-card-text v-if="!issueId">
          <p class="text-center role">No Selected Content</p>
        </b-card-text>

        <div v-else>
          <img
            class="img-fluid image-thumb"
            :src="mediaData.content[0].url"
            alt="content-thumb"
            v-if="mediaData.content[0].type === 'image'"
          />

          <vue-plyr v-else>
            <video
              class="video-player"
              :src="mediaData.content[0].url"
              :poster="mediaData.content[0].url">
              <source
                :src="mediaData.content[0].url"
                type="video/mp4"
                size="720"
              />
              <source
                :src="mediaData.content[0].url"
                type="video/mp4"
                size="1080"
              />
            </video>
          </vue-plyr>

          <div class="details mt-2">
            <div v-show="mediaData.reasons.length > 0">
              <p class="card-text title">
                <b v-if="mediaData.reasons.length > 1">Reasons</b>
                <b v-else>Reason</b>
              </p>
              <div class="reasons mb-2">
                <ul class="list pl-3">
                  <li
                    class="reason mb-1"
                    v-for="(reas, index) in mediaData.reasons"
                    :key="index">
                    {{ reas.reason }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="mb-2">
              <p class="card-text title mb-1">
                <b>Flags</b>
              </p>
              <b-badge
                class="badge mt-1 mr-1"
                v-for="(categ, index) in mediaData.categories"
                :key="index"
                variant="primary">
                {{ categ.category_name }}
                <b-badge variant="light">{{ categ.cnt }}</b-badge>
              </b-badge>
            </div>
          </div>
        </div>

        <template #footer v-if="issueId">
          <b-row>
            <b-col cols="12">
              <b-button
                class="mb-1 mt-1"
                variant="success"
                @click="resolveIssue(mediaData.ucc_id)"
                block>
                Mark as Safe
              </b-button>
            </b-col>
            <b-col cols="12">
              <b-button
                class="mb-1 mt-1"
                variant="danger"
                @click="removeMedia(mediaData.ucc_id)"
                block>
                Remove from System
              </b-button>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
  export default {
    name : 'ViewCard',
    data() {
      return {
        mediaData   : {},
        issueId     : null,
        showOverlay : false,
      }
    },
    computed : {
      owner() {
        let name = 'User';
        if (this.mediaData) {
          name = this.mediaData.contentOwner[0].firstname + ' ' +
            this.mediaData.contentOwner[0].lastname
        }

        return name;
      },
    },
    watch : {
      $route(to) {
        this.issueId = null;
        this.mediaData = {};
        if (to.params.uccId) {
          this.issueId = to.params.uccId;
          this.getIssue(to.params.uccId);
        }
      },
    },
    methods : {

      /**
       * Get Issue Details
       */
      async getIssue(id) {
        this.showOverlay = true;
        await this.$http.get('api/issue', {
          params : {
            uccId : id,
          },
        }).then(async response => {
          this.mediaData = await response.data.data[0];
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }).finally(() => {
          this.showOverlay = false;
        });
      },
      
      /**
       * Resolve Issue
       * @param id
       */
      resolveIssue(id) {
        this.$http.put('api/issues/status', {
          status : 5,
          uccId  : id,
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Resolved Issue successfully!',
          });
          this.$emit('update-issues');
          this.$router.push({ path : '/admin/moderate-content' });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },


      /**
       * Remove Media
       * @param id
       */
      removeMedia(id) {
        this.$http.put('api/issues/status', {
          status : 3,
          uccId  : id,
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Removed Issue successfully!',
          });
          this.$emit('update-issues');
          this.$router.push({ path : '/admin/moderate-content' });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },
    },
    async mounted() {
      if (this.$route.params.uccId) {
        this.issueId = this.$route.params.uccId;
        await this.getIssue(this.$route.params.uccId);
      }
    },
  }
</script>

<style lang="scss" scoped>
  // @import "../../../assets/scss/components/admin/moderate_content/view-card";

  @import "../../../assets/scss/settings";

  .image-thumb {
    width: 100%;
    // height: 10rem;//20em;
    cursor: pointer;
    object-fit: cover;
    overflow: hidden;
  }

  .video-player {
    width: 100%;
    // height: 20em;
  }

  .card-body {
    height: fit-content; //calc(100vh / 3);
  }

  .card-text {
    line-height: 1;
  }

  .name {
    margin-bottom: 0;
    font-size: 1em;
    font-weight: bold;
  }
  .role, .title {
    margin-bottom: 0;
    font-size: 0.9em;
  }

  .badge {
    font-size: 0.8rem !important;
  }

  .reasons {
    padding: 0.5rem;
    width: 100%;
    height: auto; //8rem;
    background-color: #f8f9fa;
    resize: vertical;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 12px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $primary-color;
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    }

    .list {
      font-size: 0.9em;
      list-style-type: square;
    }
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .badge { font-size: 0.6rem !important; }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {}

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .badge { font-size: 0.7rem !important; }
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {}

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .badge { font-size: 0.8rem !important; }
    .image-thumb, .video-player { height: 10rem; }
    .card-body {
      padding: 0.75rem;
    }
  }
  @media only screen and (min-width: $special) {
    .card-body {
      padding: 0.75rem;
    }
  }
  @media only screen and (min-width: $xl2-min) {
    .image-thumb, .video-player { height: 18rem; }
  }
  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {}
</style>